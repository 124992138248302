<template>
  <base-material-card
    icon="mdi-alarm-multiple"
    :title="`${lname}: Nächste Wettkämpfe`"
    color="primary"
  >
    <EventTable
      :events="events_filtered"
      overview
    />
    <ul>
      <li><span class="stbm2021bold">Fettgedruckt:</span> in Vorbereitung</li>
      <li><span class="stbm2021red">Rot:</span> Wettkampf läuft</li>
    </ul>
  </base-material-card>
</template>

<script>
import gql from 'graphql-tag'
import { deleteQuery, useGraphQL } from '@/plugins/graphql'

const query = `
  _id
  ... on StbM2021League {
    parent { ...on StbM2021 { _id name } }
    name
    shortName
    order
    events {
      _id
      name
      status
      startDate
      teams {
        team { _id name }
        home
        result { final score gp tp }
      }
      mode
    }
  }
`

export default {
  name: 'Start',

  components: {
    EventTable: () => import('./components/EventTable')
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: []
  }),

  computed: {
    lname () {
      return this.EventFind?.[0]?.parent?.name || ''
    },
    events () {
      return this.EventFind.reduce((acc, curr) => {
        acc.push(...curr.events.map(e => ({
          ...e,
          liga: curr,
          mannschaften: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            if (a.result?.final !== b.result?.final) return a.result?.final > b.result?.final ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.team.name) || [],
          ergebnisse: e.teams?.slice().sort((a, b) => {
            if (a.home !== b.home) return a.home ? -1 : 1
            if (a.result?.final !== b.result?.final) return a.result?.final > b.result?.final ? -1 : 1
            return a.order < b.order ? -1 : 1
          }).map(t => t.result) || []
        })))
        return acc
      }, []).sort((a, b) => {
        if (a.startDate !== b.startDate) return a.startDate < b.startDate ? -1 : 1
        if (a.liga.order !== b.liga.order) return a.liga.order < b.liga.order ? -1 : 1
      })
    },
    events_filtered () {
      const now = (new Date()).toISOString().split('T')[0]

      let next = this.events.slice().sort((a, b) => a.startDate < b.startDate ? -1 : 1).find(e => e.startDate >= (now + ' 00:00:00'))
      if (!next) next = { startDate: this.events.map(e => e.startDate).sort().reverse()[0] }
      if (!next?.startDate) return []
      const start = next.startDate.split(' ')[0] + ' 00:00:00'
      const end = next.startDate.split(' ')[0] + ' 23:59:59'

      return this.events.filter(e => e.startDate >= start && e.startDate <= end).sort((a, b) => a.startDate < b.startDate ? -1 : 1)
    }
  },

  methods: {
    open (item) {
      this.$router.push(`/view/${item._id}`)
    }
  },

  apollo: {
    EventFind: {
      query: gql`
          query($parent: UUID) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($parent: UUID) { EventUpdate(parent: $parent) { ${query} }}
        `
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID) { EventDelete(parent: $parent) }
        `,
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>
